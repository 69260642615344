.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-header {
  position: relative;
  line-height: 1.8em;
  background: $c-white;
  border-bottom: 1px solid $c-grey-light;

  @media (min-width: 1024px) {
    display: -webkit-flex;
    display: flex;
    min-height: $header-height;
    line-height: $header-height;
  }
}

.content-header-title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px;
  height: $header-height;

  @media (min-width: 1024px) {
    padding: 0 15px;
    width: calc(100% - 400px);
  }

  h1 {
    margin: 0;
    font-size: $f-size;
    flex-grow: 1;

    @media (min-width: 1024px) {
      display: inline-block;
      line-height: $header-height;
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .large {
    font-size: $f-size-xl;
  }

  .btn-color {
    margin-left: 5px;
    font-size: $f-size-sm;
  }
}

.content-header-entity,
.content-header-document {
  .content-header-title {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}

.content-header-tabs {
  @media (min-width: 1024px) {
    width: 400px;
  }
}

.content-header-document {
  z-index: 4;
}

.content-header-entity {
  z-index: 4;
}

@media (min-width: 1024px) {
  .main-wrapper {
    position: relative;
    min-height: 100%;
    padding-bottom: 100px;
  }
}

.library-viewer.document-viewer.unpinned-mode {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  .library-load-container {
    overflow: auto;
    height: calc(100vh - 32px);
    .footer-nav {
      display: none;
    }

    @media (min-width: 768px) {
      height: calc(100vh - 260px);
      display: block;
    }

    @media (min-width: 1024px) {
      height: calc(100vh - 225px);
    }

    .row {
      max-width: calc(100% - 5px);
    }
  }
}
