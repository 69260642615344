/** @format */

@import '../../App/scss/config/_colors.scss';
@import '../../App/scss/config/_materials.scss';
@import '../../App/scss/config/_typography.scss';

.content-header-document {
  .content-header-title {
    position: relative;
    gap: 10px;
  }

  .paginator {
    flex-grow: 1;
    text-align: center;

    a {
      display: inline;
    }
  }

  .ocr-service-display {
    line-height: normal;
    text-align: center;

    & p {
      margin: 0;
    }

    .status {
      font-style: italic;
      color: $c-grey-dark;
    }

    .ocr-error {
      padding: 7px 10px 7px 10px;
      border: 1px solid $c-black;
      border-radius: 4px;
      background-color: $c-black;
      color: white;
      font-style: italic;
    }

    .complete {
      color: $c-type-9;
    }

    &:hover .ocr-tooltip {
      display: block;
      top: unset;
      left: unset;
      right: 10px;
      bottom: unset;
      white-space: normal;
      transform: translateY(5px);
      max-width: 300px;
    }
  }
}

.document {
  background: $c-white;
  max-width: 900px;
  margin: 0 -15px;
  padding: 15px;

  @media (min-width: 1024px) {
    padding: 30px;
    margin: 0 auto;
  }

  .fake-selection {
    color: $c-success;
    background-color: $c-success-light;
  }

  b {
    font-weight: normal;
    .creation-button {
      opacity: 0;
      display: block;
      position: absolute;
      background: $c-white;
      right: 0;
      width: 31px;
      height: 31px;
      line-height: 31px;
      text-align: center;
      border-radius: 50%;
      box-shadow: $box-shadow;
      border: 1px solid $c-grey;
      color: $c-grey-dark;
      transform: translateY(-100%) translateX(50%);
      font-size: $f-size-sm;
      cursor: pointer;
    }
    &.is-active {
      background: $c-grey-light;
      padding: 4px 0 0;
      span {
        opacity: 1;
        background: $c-success-light;
        color: $c-success;
        border-color: $c-success;
      }
    }
    &.is-relation {
      background: #ffffc5;
      color: orange;
      border-color: orange;
      span {
        opacity: 1;
        background: #ffffc5;
        color: orange;
        border-color: orange;
      }
    }
  }
}

.comments-button {
  display: block;
  position: absolute;
  background: $c-white;
  right: -35px;
  width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  border-radius: 50%;
  box-shadow: $box-shadow;
  border: 1px solid $c-grey;
  color: $c-grey-dark;
  transform: translateY(-100%) translateX(50%);
  font-size: $f-size-sm;
  .fa {
    margin-right: 2px;
  }
}

.sourceDocument,
.targetDocument {
  padding: 0;
  background: transparent;
}

.page {
  position: relative;
  background: $c-white;
  box-shadow: 0 1px 10px 1px rgba(108, 108, 123, 0.2);
  margin-bottom: 20px;
}

.document.targetDocument {
  padding-top: 54px;
}

.document.targetDocument._undefined {
  display: none;
}

.document-viewer.show-target-document {
  .sourceDocument {
    display: none;
  }
  .targetDocument {
    opacity: 1;
  }
}

.loadingIcon {
  width: 100%;
  height: 150px;
  background: url('../../../../public/document-loader.gif') center no-repeat;
}

.page .textLayer {
  opacity: 1;
  mix-blend-mode: darken;
  z-index: 0;
}
.page .textLayer ::selection {
  background: #acd8ff;
}
