#pdf-container {
  margin: auto;
  width: fit-content;

  .pdf-page {
    position: relative;
  }

  & .canvasWrapper {
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;

    & canvas {
      margin: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
