@use "sass:color";

.ContextMenu {
  position: fixed;
  z-index: 1;

  &-bottom {
    bottom: 20px;
    right: 20px;
  }

  &-bottom-with-footer {
    bottom: 62px;
    right: 20px;
  }

  &-center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    .document-viewer.is-active ~ &,
    .document-viewer.is-active & {
      left: calc((100% - 400px) / 2);
    }
  }

  .btn {
    position: relative;
    border-radius: 50%;
    width: $header-height;
    height: $header-height;
    line-height: $header-height;
    font-size: $f-size-xl;
    padding: 0;
    margin: 0 6px;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }

    animation-name: ContextMenu-center;
    animation-duration: 225ms;
    @keyframes ContextMenu-center {
      0% {
        transform: scale(0.5);
        opacity: 0;
      }
      25% {
        transform: scale(1.1);
        opacity: 0.9;
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .ContextMenu-tooltip {
    width: auto;
    line-height: 1.2em;
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);
    padding: 5px;
    background-color: color.adjust($c-black, $alpha: -0.25);
    color: $c-white;
    border-radius: $border-radius;
    text-align: center;
    white-space: nowrap;
    font-size: $f-size-sm;
    &:after {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid color.adjust($c-black, $alpha: -0.25);
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .btn:hover .ContextMenu-tooltip {
    display: block;
  }

  .btn-label {
    color: $c-grey-dark;
    position: absolute;
    top: 100%;
    font-size: $f-size-sm;
    line-height: 1.5em;
    left: 50%;
    transform: translateX(-50%) translateY(2px);
    background: rgba(255, 255, 255, 0.8);
    padding: 0 5px;
  }
  &:hover .btn-label {
    color: $c-black;
  }

  .disable-click {
    svg {
      display: block;
      width: 30px;
      height: 100%;
      margin: auto;
    }
  }
}
